import { RootState } from 'redux/schemas';

export const getProgressiveQuiz = (
  state: RootState,
  id: number,
) => state.models.progressiveQuizzes[id];

export const getProgressiveQuizQuestion = (
  state: RootState,
  questionId: number,
) => state.models.quizQuestions[questionId];

export const getProgressiveQuizQuestions = (
  state: RootState,
  progressiveQuizId: number,
) => state.models.progressiveQuizzes[progressiveQuizId].questions?.map(
  (questionId) => state.models.quizQuestions[questionId] ?? null,
);

export const getQuizQuestionResponse = (
  state: RootState,
  responseId: number,
) => state.models.quizQuestionResponses[responseId] ?? null;

export const getQuizQuestionOption = (
  state: RootState,
  optionId: number,
) => state.models.quizQuestionOptions[optionId] ?? null;

export const getQuizQuestionOptions = (
  state: RootState,
  quizQuestionId: number,
) => state.models.quizQuestions[quizQuestionId]?.responseOptions.map(
  (optionId) => state.models.quizQuestionOptions[optionId],
);
