import t from 'react-translate';
import { css } from '@emotion/react';
import { isEmpty } from 'underscore';
import { useContext } from 'react';

import { useSelector } from 'react-redux';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';
import { useAppDispatch } from 'redux/store';
import { deleteQuizQuestionOption } from 'redux/actions/quizzes';
import { getFlatCourseAliases } from 'redux/selectors/course';

import { success } from 'styles/global_defaults/colors';

import NvIcon from 'shared/components/nv-icon';
import Xarrow from 'react-xarrows';
import NvTooltip from 'shared/components/nv-tooltip';
import ClickableContainer from 'components/clickable-container';
import ProgressiveQuizContext, { SavingIndicator } from 'quizzes/components/context';
import { Pair } from './matching-question-setting-section';
import QuestionSettingPairResponseOption, { getOpionId } from './question-setting-pair-response-option';

type MatchingQuestionSettingPairRowProps = {
  pair: Pair,
  disableDelete: boolean,
  arrowEndText: number,
};

const styles = css`
  &.pair-row {
    .pair-inputs {
      width: 720px;
    }

    // Only need to show when hovering or focusing on the row, so hiding initially
    .delete-btn {
      display: none;
    }

    &:hover, &:focus, &:focus-within {
      // showing the delete icon
      .delete-btn {
        display: block;
      }
    }
  }
`;

const MatchingQuestionSettingPairRow = (props: MatchingQuestionSettingPairRowProps) => {
  const { pair, disableDelete, arrowEndText } = props;

  const dispatch = useAppDispatch();
  const { registerSaving } = useContext(SavingRegistryContext);
  const {
    savingIndicatorTimeoutRef,
    canMakeStructuralChanges,
    setSavingStatus,
  } = useContext(ProgressiveQuizContext);

  const courseAliases = useSelector(getFlatCourseAliases);

  const handleDelete = () => {
    const unregister = registerSaving();
    setSavingStatus(SavingIndicator.SAVING_STATUS);
    clearTimeout(savingIndicatorTimeoutRef.current);

    dispatch(deleteQuizQuestionOption(pair.childId)).then((res) => {
      if (isEmpty(res.error)) {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      } else {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }
    }).finally(() => {
      unregister();
      savingIndicatorTimeoutRef.current = setTimeout(() => {
        setSavingStatus(SavingIndicator.HIDDEN_STATUS);
      }, 2000);
    });
  };

  const canDeletePair = disableDelete || !canMakeStructuralChanges;
  const getDeleteTooltip = () => {
    if (!canMakeStructuralChanges) {
      return t.QUIZZES.ALREADY_RELEASED.OPTION_DELETE({ ...courseAliases, isPair: true });
    }

    if (disableDelete) {
      return t.QUIZZES.MATCHING_QUESTION.CANNOT_DELETE_PAIR();
    }

    return t.QUIZZES.MATCHING_QUESTION.DELETE_PAIR();
  };

  return (
    <div css={styles} className='pair-row d-flex align-items-center'>
      <div className='pair-inputs d-flex align-items-stretch justify-content-between'>
        <QuestionSettingPairResponseOption
          id={pair.parentId}
          arrowText={arrowEndText}
          dotPlacement='right'
        />
        <QuestionSettingPairResponseOption
          id={pair.childId}
          arrowText={arrowEndText}
          dotPlacement='left'
        />
      </div>
      <Xarrow
        start={getOpionId(pair.parentId)} // start id
        end={getOpionId(pair.childId)} // end id
        startAnchor='right'
        endAnchor='left'
        strokeWidth={2}
        color={success}
        showHead={false}
        showTail={false}
      />
      <NvTooltip
        text={getDeleteTooltip()}
      >
        <ClickableContainer
          onClick={handleDelete}
          disabled={canDeletePair}
          isFocusable={!canDeletePair}
        >
          <NvIcon
            icon='trash'
            size='small'
            className={`delete-btn text-${canDeletePair ? 'gray-5' : 'primary'} ml-1`}
          />
        </ClickableContainer>
      </NvTooltip>
    </div>
  );
};

export default MatchingQuestionSettingPairRow;
