import t from 'react-translate';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import sanitizeHtml from 'sanitize-html';

import { useSelector } from 'react-redux';
import { RootState } from 'redux/schemas';
import { useAppDispatch } from 'redux/store';
import { editQuizQuestionOption } from 'redux/actions/quizzes';
import { getQuizQuestionOption } from 'redux/selectors/quizzes';

import {
  doubleSpacing, halfSpacing, quarterSpacing, standardSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  gray4, gray6, primary, success, white,
} from 'styles/global_defaults/colors';

import { ToolbarOptions } from 'froala/helpers/nv-froala-constants';
import NvFroala from 'froala/components/nv-froala';
import ProgressiveQuizContext, { SavingIndicator } from 'quizzes/components/context';
import NvTooltip from 'shared/components/nv-tooltip';
import useDebouncedEffect from 'shared/hooks/use-debounced-effect';
import { SavingRegistryContext } from 'shared/hooks/use-saving-registry';

type QuestionSettingPairResponseOptionProps = {
  id: number,
  arrowText: number,
  dotPlacement: 'right' | 'left',
};

export const getOpionId = (id: number) => `pair-answer-option-${id}`;
const MAX_INPUT_HEIGHT = 100;

const styles = css`
  &.input-container {
    position: relative;
    width: 320px;
    border: 1px solid ${gray6};
    border-radius: ${quarterSpacing}px;
    box-shadow: 0px 4px 10px 0px #0000001A;

    // To allow child elements to expand to full width while typing in the related flex column.
    & div:not(.gradiant-box, .dot) {
      height: auto;
    }

    &:focus-within {
      border-color: ${primary};
    }

    .pair-content {
      .fr-wrapper {
        margin: ${standardSpacing}px;

        &:not(.show-placeholder) .fr-element {
          background-color: ${white} !important;
        }
      }

      .fr-element {
        max-height: ${MAX_INPUT_HEIGHT}px;
        overflow-y: hidden;
        outline: none;
        padding: 0;

        &:focus {
          outline: none !important;
        }

        p:last-child {
          margin: 0px;
        }
      }

      .fr-placeholder {
        background-color: ${white};
        color: ${gray4};
        border: none !important;
        border-radius: ${quarterSpacing}px;
        height: auto;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .dot {
      position: absolute;
      width: ${standardSpacing}px;
      height: ${standardSpacing}px;
      border-radius: 50%;
      background-color: ${success};
      z-index: 1;
    }
    .dot-right {
      right: -${halfSpacing}px;
    }
    .dot-left {
      left: -${halfSpacing}px;
    }

    .gradiant-box {
      position: absolute;
      width: 100%;
      height: ${doubleSpacing}px;
      bottom: ${standardSpacing}px;
      background: linear-gradient(360deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 1;
      pointer-events: none;
    }
  }
`;

const QuestionSettingPairResponseOption = (props: QuestionSettingPairResponseOptionProps) => {
  const { id, arrowText, dotPlacement } = props;
  const defaultContentTemplate = '<p class="froala-style-medium froala-text-align-center"><br></p>';
  const [showTooltip, setShowTooltip] = useState(false);

  const mountedRef = useRef(false);

  const dispatch = useAppDispatch();

  const questionOption = useSelector(
    (state: RootState) => getQuizQuestionOption(state, (id)),
  );

  const [inputValue, setInputValue] = useState(questionOption?.optionContent || defaultContentTemplate);

  const {
    savingIndicatorTimeoutRef,
    setSavingStatus,
  } = useContext(ProgressiveQuizContext);
  const { registerSaving } = useContext(SavingRegistryContext);

  const checkContentOverflow = useCallback(() => {
    const element = document.querySelector(`[id="${getOpionId(id)}"] .fr-element`);
    const contentHeight = element?.scrollHeight;

    if (contentHeight > MAX_INPUT_HEIGHT) {
      setShowTooltip(true);
    }
  }, [id]);

  useEffect(() => {
    // Checking the the initial content overflow only after DOM has rendered
    requestAnimationFrame(checkContentOverflow);
  }, [checkContentOverflow]);

  const onContentBlur = (e) => {
    checkContentOverflow();
  };

  const onContentFocus = (e) => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  };

  useDebouncedEffect(() => {
    if (mountedRef.current) {
      const unregister = registerSaving();
      setSavingStatus(SavingIndicator.SAVING_STATUS);
      clearTimeout(savingIndicatorTimeoutRef.current);

      dispatch(editQuizQuestionOption(
        {
          id,
          patch: { optionContent: inputValue },
        },
      )).then(() => {
        setSavingStatus(SavingIndicator.SUCCESS_STATUS);
      }).catch(() => {
        setSavingStatus(SavingIndicator.ERROR_STATUS);
      }).finally(() => {
        unregister();
        savingIndicatorTimeoutRef.current = setTimeout(() => {
          setSavingStatus(SavingIndicator.HIDDEN_STATUS);
        }, 2000);
      });
    } else {
      mountedRef.current = true;
    }
  }, 250, [inputValue]);

  return (
    <div
      css={styles}
      className='input-container d-flex align-items-center'
      id={getOpionId(id)}
    >
      <NvTooltip
        text={sanitizeHtml(questionOption?.optionContent, { allowedTags: [] })}
        enabled={showTooltip}
      >
        <div className='w-100'>
          <NvFroala
            keepFormatOnDelete
            blockBackspaceOnEmpty
            className='pair-content w-100'
            minHeight={25}
            placeholder={t.FORM.TYPE_HERE()}
            allowToolbar
            value={inputValue}
            onChange={(newContent) => {
              setInputValue(newContent);
            }}
            onBlur={(onContentBlur)}
            onFocus={onContentFocus}
            toolbarButtons={[
              ToolbarOptions.COLOR,
              ToolbarOptions.BOLD,
              ToolbarOptions.ITALIC,
              ToolbarOptions.UNDERLINE,
              ToolbarOptions.PARAGRAPH_STYLE,
            ]}
          />
        </div>
      </NvTooltip>
      {showTooltip && <div className='gradiant-box' />}
      <div className={`dot dot-${dotPlacement} d-flex align-items-center justify-content-center text-small bold text-white`}>
        {arrowText}
      </div>
    </div>
  );
};

export default QuestionSettingPairResponseOption;
